import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'element',
    'description',
    'fundamentalsDescription',
    'materialnessDescription',
    'personal_data',
    'protected_people',
    'fundamentals',
    'materialness',
    'observeButtonSection',
    'clues',
    'keeps',
    'badFaithSection',
    'deriveButtonSection'
  ]

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = !el.disabled
    })
  }

  organ(evt) {
    let reportOrganSelect = null
    let derivationNumber = null
    let reportsOrgan = document.querySelectorAll('[id^="report_report_organs_attributes_"][id$="organ_id"]')
    reportsOrgan.forEach(reportOrgan => {
      if (reportOrgan.value == evt.currentTarget.dataset.organId) {
        reportOrganSelect = reportOrgan
        derivationNumber = document.getElementById(
          reportOrgan.id.substr(0, reportOrgan.id.indexOf('organ_id')) + 'derivation_number'
        )
        reportOrganSelect.disabled = !evt.currentTarget.checked
        derivationNumber.disabled = !evt.currentTarget.checked
        return
      }
    })
  }

  grant_protective(event) {
    const description = this.descriptionTarget
    event.currentTarget.checked ? description.classList.remove('hidden') : description.classList.add('hidden')
  }

  toggleDescription(event) {
    const descriptionSection =
      event.currentTarget.name.search('fundamentals') > 0
        ? this.fundamentalsDescriptionTarget
        : event.currentTarget.name.search('materialness') > 0
        ? this.materialnessDescriptionTarget
        : null

    if (descriptionSection) {
      const showSection = event.currentTarget.value == 1
      showSection ? descriptionSection.classList.remove('hidden') : descriptionSection.classList.add('hidden')
    }
  }

  toogleObserveButton(event) {
    const observeButtonSection = this.observeButtonSectionTarget
    const personalDataValue = this.personal_dataTargets.find(el => el.checked)?.value
    const protectedPeopleValue = this.protected_peopleTargets.find(el => el.checked)?.value
    const fundamentalsValue = this.fundamentalsTargets.find(el => el.checked)?.value
    const materialnessValue = this.materialnessTargets.find(el => el.checked)?.value

    const requirementReviewAnswers = [personalDataValue, protectedPeopleValue, fundamentalsValue, materialnessValue]
    const showButton = requirementReviewAnswers.some(answer => answer === '0')

    showButton ? observeButtonSection.classList.remove('hidden') : observeButtonSection.classList.add('hidden')
  }

  toogleBadFaithSection(event) {
    const section = this.badFaithSectionTarget
    const showSection = event.currentTarget.value == 1
    showSection ? section.classList.remove('hidden') : section.classList.add('hidden')
  }

  toogleDeriveButton(event) {
    const deriveButtonSection = this.deriveButtonSectionTarget
    const cluesValue = this.cluesTargets.find(el => el.checked)?.value
    const keepsValue = this.keepsTargets.find(el => el.checked)?.value

    const badFaithPresumptionAnswers = [cluesValue, keepsValue]
    const showButton = badFaithPresumptionAnswers.every(answer => answer === '1')

    showButton ? deriveButtonSection.classList.remove('hidden') : deriveButtonSection.classList.add('hidden')
  }

  enable(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = false
      el.classList.remove('disabled') //en caso el elemento tenga "disabled: true" en su html ya que en su class se quedará pegada la clase disabled
    })
  }

  disable(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = true
    })
  }

  enabledisable(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = false
      if (event.target.value == el.getAttribute('data-key')) {
        el.disabled = true
      }
    })
  }
}
