import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['doc_type', 'doc_number', 'person_name', 'validation_group', 'alert_input', 'validate_button']

  search(event) {
    const controlDocument = this.doc_numberTarget
    const alertDocument = this.alert_inputTarget
    const personName = this.person_nameTarget
    const baseUrl = this.element.getAttribute('data-search-url')
    const searchUrl = `${baseUrl}?document_number=${controlDocument.value}`

    if (!controlDocument.value.length) {
      alertDocument.innerHTML = 'El número de documento es requerido.'
      return
    }

    this.updateValidateButton('Validando', true)
    controlDocument.setAttribute('readonly', '')

    Rails.ajax({
      type: 'GET',
      url: searchUrl,
      success: data => {
        this.updateValidateButton('Validar DNI')
        personName.value = data?.name
      },
      error: data => {
        alertDocument.innerHTML = data?.error
        this.updateValidateButton('Validar DNI')
        controlDocument.removeAttribute('readonly')
      }
    })
  }

  updateValidateButton(text, showLoading = false) {
    const buttonValidate = this.validate_buttonTarget

    buttonValidate.innerText = text

    if (showLoading) {
      buttonValidate.classList.add('control-loading', 'pl-4')
    } else {
      buttonValidate.classList.remove('control-loading', 'pl-4')
    }
  }

  selectDocument(event) {
    const doc_type = this.doc_typeTarget
    const doc_number = this.doc_numberTarget
    const person_name = this.person_nameTarget

    person_name.value = ''

    doc_number.removeAttribute('readonly')
    person_name.removeAttribute('readonly')
    if (doc_type.value === 'dni') person_name.setAttribute('readonly', '')

    this.actionDocument(doc_type)
  }

  actionDocument(type) {
    const controlDocument = this.doc_numberTarget
    const alertDocument = this.alert_inputTarget
    const validate_group = this.validation_groupTarget
    const buttonValidate = this.validate_buttonTarget

    const document = this.getTypeDocument(type.value)
    const regex = document ? new RegExp(document.regex) : ''

    const isInvalid = this.validateDocumentFormat(controlDocument.value, regex, alertDocument, document)
    if (!isInvalid && type.value === 'dni') {
      validate_group.classList.remove('hidden')
      buttonValidate.innerHTML = `Validar ${document.description}`
    } else {
      validate_group.classList.add('hidden')
    }
  }

  getTypeDocument(type) {
    const types = [
      { document: 'dni', description: 'DNI', regex: /^[0-9]{8}$/, char: '8' },
      { document: 'immigration_card', description: 'carnet de extranjería', regex: /^[0-9]{9}$/, char: '9' },
      { document: 'passport', description: 'pasaporte', regex: /^[0-9]{9}$/, char: '9' }
    ]
    return types.find(item => item.document == type)
  }

  validateDocumentFormat(value, regex, alertDocument, document) {
    const match = regex ? !regex.test(value) : false
    alertDocument.innerHTML = ''
    if (value.length && match) {
      alertDocument.innerHTML = `El número de ${document.description} debe tener ${document.char} dígitos. Ingresa un número válido.`
    }
    return match
  }

  clear() {
    const controlDocument = this.doc_numberTarget
    const personName = this.person_nameTarget
    const alertDocument = this.alert_inputTarget
    const validate_group = this.validation_groupTarget

    controlDocument.value = ''
    personName.value = ''
    alertDocument.innerHTML = ''

    controlDocument.removeAttribute('readonly')

    validate_group.classList.add('hidden')
  }
}
