import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['first_question', 'second_question']

  filterQuestions(event) {
    const questionSelected = event.currentTarget.value
    const firstQuestion = this.first_questionTarget
    const secondQuestion = this.second_questionTarget

    secondQuestion.innerHTML = ''

    Array.from(firstQuestion.options).map(option => {
      if (option.value != questionSelected) secondQuestion.add(new Option(option.text, option.value))
    })
  }
}
