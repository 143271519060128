import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['body']

  toggle(event) {
    event.preventDefault()
    const header = event.target.closest('.js-accordion-header')
    let body = this.bodyTarget

    body.classList.toggle('hidden')
    header.querySelectorAll('.js-accordion-icon').forEach(element => {
      element.classList.toggle('hidden')
    })
  }
}
