document.addEventListener('turbolinks:load', () => {
  for (let el of document.querySelectorAll('.js-change-text')) {
    el.addEventListener('change', function () {
      target = document.querySelector('.js-text-label')

      if (this.value == 'ruc') target.innerText = '8. DNI de representante legal'
      else target.innerText = '2. Número de documento'
    })
  }
})
